.message-notification-menu {
  width: 300px;
}

a.message-notification-item {
  white-space: normal;
}

span.message-notification-preview {
  display:block;
  color: #666;
  line-height: 1.2rem;
  font-size: 0.9rem;
}
