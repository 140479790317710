.defaultCustomer {
  font-size: 1.6rem;
}

.noImportDates {
  font-size: 1.1rem;
  font-style:italic;
}

.rowError, .rowErrorDetail  {
  border-left: 6px solid #842029;
  background-color:  #fff7f8;
}

.rowError td {
  border-bottom:0px !important;
}

.rowErrorDetail td {
  padding-top:0px !important;
}

.rowHasChanged {
  border-left: 6px solid #6f6f74;
  border-bottom: 1px solid #ccccd6;
  background-color: #eaeaf5;
}

.fieldHasChanges {
  border: 2px solid #6f6f74 !important;
}