.fileBrowseModal {
  width: 90% !important;
  height: 90% !important;
  max-width: none !important;
}

.modalContent {
  height:100%;
}

.modalBody {
  padding: 0 !important;
  display:flex;
  flex-direction: column;
}
