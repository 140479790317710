.sftpConfigHeading {
  font-size: 1.4rem;
  font-weight: normal;
}

.descriptionMessage, .noAccountMessage {
  margin-bottom:0.75rem;
  font-size:1.1rem;
}

.userContainer {
  display: flex;
  flex-wrap: wrap;
}

.userContainer > div {
  padding: 0.2rem 1rem;
  padding-right: 1.5rem
}

.userContainer > div:nth-child(3) {
  border-left: 1px solid #ccc;
}

.usernameDisplay, .passwordDisplay, .fakePasswordDisplay, .serverDisplay {
  font-size: 1.3rem;
}
.fakePasswordDisplay {
  line-height: 1rem;
  margin:1.2rem 0 0.3rem 0;
}
.deleteBtnContainer {
  margin-top:1.3rem;
  padding-top:1rem;
  border-top: 1px solid #ddd;
}