.expirationFormGroup {
  display:flex;
  align-items: center;
}

.fileExpirationField {
  margin-right: 0.25rem;
  text-align: center;
}

h3.sectionHeading {
  font-size: 18px;
  font-weight: normal;
}

fieldset.section {
  border: 1px solid #ddd;
  border-radius: 4px;
  padding: 1rem;
  padding-top: 0.25rem;
  margin-bottom: 1.75rem;
}
legend.sectionLegend {
  float: none;
  font-size: 1.1rem !important;
  font-weight: bold;
  width: inherit;
  padding: 0 0.5rem;
}

.sectionDescription {
  padding-bottom:0.5rem;
  font-size: 0.875rem;
  line-height: 1.25rem;
}

.generatedFileName {
  border: 2px solid #0d6efd;
  font-family: monospace;
  font-size: 1.7rem;
  width: 100%;
  padding: 0.5rem 1rem;
}

.generatedFileName > span {
  display:inline-block;
  color:#9aa1b8;
  cursor: help;
}

.dateLabel {
  font-weight: normal;
  margin-right: 0.5rem;
}

.dateSelect {
  max-width:155px;
  margin-right: 0.5rem !important;
}