.loading-container {
  width: 100vw;
  height: 100vh;
  background: rgb(244,245,252);
  background: radial-gradient(circle, rgba(244,245,252,1) 0%, rgba(177,180,196,1) 100%);
  overflow: hidden;
  display:flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  position: fixed;
  z-index: 1000;
}

.loading-progress-bg {
  background-color: rgba(150, 157, 163, 0.4) !important;
}

.loading-progress-bar {
  background-color: #981d97 !important;
}
