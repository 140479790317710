

@media print {
  
    body.modal-open #root {
      display: none !important;
    }

    .modal-backdrop {
      display:none;
    }
  
    .modal {
      visibility: hidden;
    }
  
    .paypalDetailsModalContainerX {
      visibility: visible !important;
      min-width: 100vw;
      margin:0 !important;
      padding:0 !important;
      background-color: #fff;
      position:  relative !important;
      overflow-y: visible !important;
      overflow-x: visible !important;
    }
  
    .modal-dialog {
      min-width: 100vw;
      margin:0 !important;
      padding:0 !important;
    }
  
    .batch-details-button-row, .batch-details-button-row-divider {
      display:none !important;
    }
  
    .modal-content {
      border: 0 !important;
    }
  
    .btn-close {
      display:none !important;
    }
  }