
.transaction-summary-table-1 > tbody > tr > td:nth-child(odd) {
    width: 150px;
    padding: 5px;
  }
  .transaction-summary-table-1 > tbody > tr > td:nth-child(even) {
    align-self: left;
    padding: 5px;
  }
  .transaction-summary-table-2 > tbody > tr > td:nth-child(odd) {
    width: 150px;
    padding: 5px;
  }
  .transaction-summary-table-2 > tbody > tr > td:nth-child(even) {
    align-self: left;
    width: 150px;
    padding: 5px;
  }
  .transaction-summary-table-3 > tbody > tr > td:nth-child(odd) {
    width: 150px;
    padding: 5px;
  }
  .transaction-summary-table-3 > tbody > tr > td:nth-child(even) {
    align-self: left;
    padding: 5px;
  }
  .transaction-summary-border-box > tbody > tr > td {
    border: 1px solid;
    border-collapse: collapse;
  }  
  .transaction-summary-table-centered > thead > tr > th {
    text-align: center;
  }
  .transaction-summary-table-centered > tbody > tr > td {
    text-align: center;
  }
  .transaction-summary-gray {
    color: gray;
  }
  .transaction-config-heading {
    font-size: 24px;
    font-weight: normal;
    margin-bottom:1.5rem;
  }
  .transaction-config-time {
    font-size: 18px;
    font-weight: normal;
    margin-bottom:1.4rem;
  }

  .transaction-config-section-header, .transaction-config-section-header th {
    font-size: 16px !important;
    font-weight: normal;
    color:#fff;
    background-color: #57575f !important;
    padding:0.4rem 0.4rem !important;
  }
