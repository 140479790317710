
body.theme-UnitedBank a {
  color: #017199;
}

/* PRIMARY BUTTON */
body.theme-UnitedBank .btn {
  background: #017199;
  color: white;
}

body.theme-UnitedBank .btn:hover,
body.theme-UnitedBank .btn:focus,
body.theme-UnitedBank .btn:active {
  background: #015472;
}

body.theme-UnitedBank .btn:disabled {
  background: #015472aa;
}

/* SECONDARY BUTTON */
body.theme-UnitedBank .btn-secondary {
  background: #00465f;
}

body.theme-UnitedBank .btn-secondary:hover,
body.theme-UnitedBank .btn-secondary:focus,
body.theme-UnitedBank .btn-secondary:active {
  background: #00374b;
}

body.theme-UnitedBank .btn-secondary:disabled {
  background: #00374baa;
}


/* SUCCESS BUTTON */
body.theme-UnitedBank .btn-success {
  background: #358d3a;
}

body.theme-UnitedBank .btn-success:hover,
body.theme-UnitedBank .btn-success:focus,
body.theme-UnitedBank .btn-success:active {
  background: #115816;
}
body.theme-UnitedBank .btn-success:disabled {
  background: #115816aa;
}


/* DANGER BUTTON */
body.theme-UnitedBank .btn-danger {
  background: #c94954;
}

body.theme-UnitedBank .btn-danger:hover,
body.theme-UnitedBank .btn-danger:focus,
body.theme-UnitedBank .btn-danger:active {
  background: #9c3941;
}
body.theme-UnitedBank .btn-danger:disabled {
  background: #9c3941aa;
}

/* TABLE */
body.theme-UnitedBank .table-striped>tbody>tr:nth-child(odd)>td,
body.theme-UnitedBank .table-striped>tbody>tr:nth-child(odd)>th {
	background-color: #e0eff5;
  box-shadow: none !important;
}
body.theme-UnitedBank .table-striped>tbody>tr:hover>td,
body.theme-UnitedBank .table-striped>tbody>tr:hover>th {
	background-color: #b5dbe8;
  box-shadow: none !important;
}

/* PAGING */
body.theme-UnitedBank .page-item .page-link {
  color: #017199;
}

body.theme-UnitedBank .page-item.active .page-link {
  color:#fff;
  background: #017199;
  border-color: #017199;
}



body.theme-UnitedBank #sidebar-wrapper {
  background: white;
  background: -moz-linear-gradient(top, white 0%, white 30%, #017199 100%);
  background: -webkit-linear-gradient(top, white 0%, white 30%, #017199 100%);
  background: linear-gradient(to bottom, #ffffff 0%, #ffffff 30%, #017199 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffffff', endColorstr='#017199',GradientType=0 );
}

body.theme-UnitedBank #sidebar-wrapper .list-group .list-group-item {
  color: black;
}

body.theme-UnitedBank #sidebar-wrapper a {
  color: white;
  text-decoration: none;
}

body.theme-UnitedBank .navbar {
  background: #b5dbe8;
}

body.theme-UnitedBank .navbar #sidebarToggle {
  border-color: #017199;
  color: #017199;
}

body.theme-UnitedBank .navbar .myAccount .circle {
  background: #017199 !important;
  color: white;
}

body.theme-UnitedBank #page-content-wrapper {
  background: #e8f3f7;
}

body.theme-UnitedBank .accordion-button, body.theme-UnitedBank .accordion-button:not(.collapsed) {
  color: #017199;
}

body.theme-UnitedBank .card-header h2 {
  color: #017199;
}

body.theme-UnitedBank .themed-header-bar {
  background: #b5dbe8;
}

body.theme-UnitedBank .themed-bg-color {
  background: #e8f3f7;
}

body.theme-UnitedBank .themed-bg-dark {
  background: #017199;
  color: white;
}
