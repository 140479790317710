.resultTxHeading {
  font-size: 1.2rem;
  font-weight: bold;
  line-height: 110%;
  margin-bottom: 0.75rem;
}

.fileSelectContainer {
  display: flex;
  border: 1px solid #c9cad6;
  padding: .375rem .75rem;
  padding-right: 0.5rem;
  border-radius: .25rem;
  justify-content: space-between;
}

.selectedFileName {
  font-weight: normal;
  margin-right: 1rem;
  cursor: pointer;
}


.scheduleTypeOptions {
  display: flex;
  flex-wrap: wrap;
  margin-top: 0.5rem;
}

.scheduleTypeOption {
  padding-left: 0 !important;
  flex-grow: 1;
  margin-right: 1rem;
  margin-bottom: 0.5rem;
}

.scheduleTypeOption:last-child {
  margin-right: 0;
}

.scheduleTypeLabel {
  padding:1rem;
  padding-left:2rem;
  border: 1px solid #f0f2f5;
  background: #f0f2f5;
  border-radius: 5px;
  width: 100%;
}

.scheduleTypeLabelActive {
  padding:1rem;
  padding-left:2rem;
  background: #f0f2f5;
  border: 1px solid #0d6efd;
  border-radius: 5px;
  width: 100%;
}

a.detailLink {
  font-size: 0.875rem;
  text-decoration: none;
  display:block;
  margin-top: 0.5rem;
}