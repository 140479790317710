.transaction-show-inactive {
  margin-top: 0.25rem;
  margin-bottom: 0.3rem;
}

label.inactive-transaction {
  color:#b00;
}

.transaction-select-message {
  margin-top: 0.5rem;
  font-weight: bold;
  font-size: 0.875rem;
}

.transaction-select-all {
  margin-top: 0.25rem;
  margin-bottom: 0.7rem;
}

.transaction-container {
  overflow-x: auto;
  max-height: 200px;
  padding: 0.6rem 0.3rem;
  padding-top: 1rem;
  background-color: #fff;
  border: 1px solid rgba(0,0,0,.125)
}

.fullscreen-report {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  overflow-x: hidden;
  overflow-y: auto;
  z-index: 50;
  margin: 0;
  padding: 0.5rem !important;
}

.fullscreen-report div.searchContainer {
  padding: 1rem;
}

.fullscreen-report div.report-container {
  max-height: inherit;
  height: calc( 100vh - 80px);
}