div.dashboard-tile {
  box-shadow: 5px 0px 15px 0px rgba(0, 0, 0, 0.06);
  position: relative;
  min-height: 200px;
  height: 100%;
}
div.dashboard-tile-grip {
  position: absolute;
  right: 1%;
  top: 1%;
  opacity: 0.6;
}
h3.dashboard-tile-title {
  font-size: 1.2rem;
  font-weight: bold;
}
div.dashboard-tile-body a {
  text-decoration: none;
}


