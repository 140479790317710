.audit-report-container {
  max-height: 600px;
  overflow: auto;
  padding: 0rem 2rem 2rem 2rem;
  border: 1px solid rgba(0, 0, 0, 0.125);
  font-size: 0.875rem;
}

.audit-table-primary {
  table-layout: fixed;
  padding: 0;
}
.audit-table-secondary {
  table-layout: fixed;
  padding: 0;
  margin: 0;
}

.audit-table-primary > thead > tr > th {
  padding-top: 2rem;
}
.audit-table-primary > thead > tr {
  font-size: 1.1em;
  position: sticky;
  top: 0px;
  background-color: white;
  z-index: 9999;
}

.audit-table-primary > thead > tr > th,
.audit-table-primary > tbody > tr > td {
  text-align: center;
}

.audit-table-primary > thead > tr > th.audit-date {
  width: 15%;
}
.audit-table-primary > thead > tr > th.audit-modified-target {
  width: 20%;
}
.audit-table-primary > thead > tr > th.audit-action {
  width: 21%;
}
.audit-table-primary > thead > tr > th.audit-modified-values {
  width: 49%;
  text-align: left;
}
.audit-table-secondary > tbody > tr > td.audit-action {
  width: 30%;
}
.audit-table-secondary > tbody > tr > td.audit-modified-values {
  text-align: left;
  width: 65%;
}
#no-padding {
  padding: 0;
}
