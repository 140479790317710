
body.theme-FirstFederal a {
  color: #233b7b;
}

/* PRIMARY BUTTON */
body.theme-FirstFederal .btn {
  background: #233b7b;
  color: white;
}

body.theme-FirstFederal .btn:hover,
body.theme-FirstFederal .btn:focus,
body.theme-FirstFederal .btn:active {
  background: #192b5a;
}

body.theme-FirstFederal .btn:disabled {
  background: #192b5aaa;
}

/* SECONDARY BUTTON */
body.theme-FirstFederal .btn-secondary {
  background: #2380bc;
}

body.theme-FirstFederal .btn-secondary:hover,
body.theme-FirstFederal .btn-secondary:focus,
body.theme-FirstFederal .btn-secondary:active {
  background: #1b6392;
}

body.theme-FirstFederal .btn-secondary:disabled {
  background: #1b6392aa;
}


/* SUCCESS BUTTON */
body.theme-FirstFederal .btn-success {
  background: #358d3a;
}

body.theme-FirstFederal .btn-success:hover,
body.theme-FirstFederal .btn-success:focus,
body.theme-FirstFederal .btn-success:active {
  background: #115816;
}
body.theme-FirstFederal .btn-success:disabled {
  background: #115816aa;
}


/* DANGER BUTTON */
body.theme-FirstFederal .btn-danger {
  background: #c94954;
}

body.theme-FirstFederal .btn-danger:hover,
body.theme-FirstFederal .btn-danger:focus,
body.theme-FirstFederal .btn-danger:active {
  background: #9c3941;
}
body.theme-FirstFederal .btn-danger:disabled {
  background: #9c3941aa;
}

/* TABLE */
body.theme-FirstFederal .table-striped>tbody>tr:nth-child(odd)>td,
body.theme-FirstFederal .table-striped>tbody>tr:nth-child(odd)>th {
	background-color: #d7e8f3;
  box-shadow: none !important;
}
body.theme-FirstFederal .table-striped>tbody>tr:hover>td,
body.theme-FirstFederal .table-striped>tbody>tr:hover>th {
	background-color: #2380bc66;
  box-shadow: none !important;
}

/* PAGING */
body.theme-FirstFederal .page-item .page-link {
  color: #233b7b;
}

body.theme-FirstFederal .page-item.active .page-link {
  color:#fff;
  background: #233b7b;
  border-color: #233b7b;
}


body.theme-FirstFederal #sidebar-wrapper {
  background: white;
  background: -moz-linear-gradient(top, white 0%, #2581bc 50%, #233b7b 100%);
  background: -webkit-linear-gradient(top, white 0%, #2581bc 50%, #233b7b 100%);
  background: linear-gradient(to bottom, #ffffff 0%, #2581bc 50%, #233b7b 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffffff', endColorstr='#233b7b',GradientType=0 );
}
body.theme-FirstFederal #sidebar-wrapper .list-group .list-group-item {
  color: black;
}
body.theme-FirstFederal #sidebar-wrapper a {
  color: white;
  text-decoration: none;
}
body.theme-FirstFederal .navbar {
  background: #c9e1f1;
}
body.theme-FirstFederal .navbar #sidebarToggle {
  border-color: #233b7b;
  color: #233b7b;
}
body.theme-FirstFederal .navbar .myAccount .circle {
  background: #2581bc !important;
  color: white;
}
body.theme-FirstFederal #page-content-wrapper {
  background: #f1f9ff;
}
body.theme-FirstFederal .accordion-button, body.theme-FirstFederal .accordion-button:not(.collapsed) {
  color: #233b7b;
}
body.theme-FirstFederal .card-header h2 {
  color: #233b7b;
}

body.theme-FirstFederal .themed-header-bar {
  background: #c9e1f1;
}

body.theme-FirstFederal .themed-bg-color {
  background: #f1f9ff;
}

body.theme-FirstFederal .themed-bg-dark {
  background: #233b7b;
  color: white;
}