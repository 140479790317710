.container {
  margin-top:0.5rem;
  max-height: 310px;
  overflow: auto;
}

.reportItem {
  line-height: 1.3rem;
}

.noReports {
  text-align: center;
  font-style: italic;
  margin-top: 1rem;
  color:#777;
}