.scheduleReportModal {
  max-width: 960px !important;
}

.viewReportLink, .viewReportLinkActive {
  display: block;
  margin-bottom:0.25rem;
  color: #333 !important;
  background: #f3f4fa;
  padding: 0.1rem 0.4rem;
  text-decoration: none;
  font-size: 0.875rem;
}

.viewReportLink:hover {
  background: #dadce8;
}

.viewReportLinkActive {
  background: #dadce8;
  border-left: 4px solid #767881;
}

.reportDetailRow {
  padding: 0.25rem 1rem;
  background:#dadce8;
  display:flex;
  justify-content: space-between;
}

.generatedReportList {
  overflow: auto;
  max-height: 450px;
}