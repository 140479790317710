.tileLink {
  color:#444 !important;
  background: #fff;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 8px;
  box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.06);
  text-align: center;
  text-decoration: none;
  width: 150px;
  height: 130px;
}

.tileLink:hover {
  border: 2px solid rgba(0, 0, 0, 0.4);
  box-shadow: 0px 0px 10px 0px rgba(145, 168, 198, 0.6);
  transform: scale(1.1);
}

.tileIcon {
  display:flex;
  font-size: 50px;
  color:rgba(75, 85, 107, 0.7);
  flex-grow: 1;
  justify-content: center;
  align-items: center;
}

.tileLink:hover .tileIcon {
  color: rgba(75, 85, 107, 0.9);
}

.tileText {
  font-size: 0.875rem;
  line-height: 1.1rem;
}