.reportDetailName {
  font-size:1.3rem;
  margin-bottom:1rem;
  margin-top:0rem;
}

.viewReportLink, .viewReportLinkActive {
  display: block;
  margin-bottom:0.25rem;
  color: #333 !important;
  background: #f3f4fa;
  padding: 0.1rem 0.4rem;
  text-decoration: none;
  font-size: 0.875rem;
}

.viewReportLink:hover {
  background: #dadce8;
}

.viewReportLinkActive {
  background: #dadce8;
  border-left: 4px solid #767881;
}