
.searchContainer {
  display: flex;
  flex-wrap: wrap;
  margin-top: 30px;
  margin-bottom: 30px;
}
.searchContainer.border {
  border: 1px solid #cdcde3 !important;
  border-radius: .25rem;
  padding: 2rem;
}
.searchContainer .heading {
  width: 100%;
}
.searchContainer .form-label {
  margin: 0;
}
.searchContainer .searchWrap {
  flex: 1;
}
.searchContainer .searchWrap + * {
  margin-left: 10px;
}

.searchWrap {
  position: relative;
}
.searchWrap .searchSubmit {
  background: none;
  border: none;
  font-size: 0.833rem;
  padding: 0;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 14px;
}

.resultCount {
  float:right;
  font-weight: bold;
}

.billWrap > div {
  width: 50%;
}

#phoneHelp {
  line-height: 1.1rem;
  display: inline-block;
}

.icon-button {
  padding: 0.4rem !important;
  margin: 0.1rem !important;
  width: 30px;
}

.researchCustomerName {
  font-size: 1.2rem;
}

tr.row-viewed td {
  background-color: #fff5cc !important;
}

.transaction-show-inactive {
  margin-top: 0.25rem;
  margin-bottom: 0.3rem;
}

.form-select option.inactive {
  color:#b00;
}