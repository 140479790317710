
.payPalSearchContainer {
  display: flex;
  flex-wrap: wrap;
  margin-top: 0;
}
.payPalSearchContainer.border {
  border: 1px solid #cdcde3 !important;
  border-radius: .25rem;
  padding: 2rem;
}

.paypal-date-label {
  min-width: 40px;
}

.paypal-search-date {
  max-width: 170px;
}

.payPalSearchContainer .heading {
  width: 100%;
}
.payPalSearchContainer .form-label {
  margin: 0;
}
.payPalSearchContainer .searchWrap {
  flex: 1;
}
.payPalSearchContainer .searchWrap + * {
  margin-left: 10px;
}

.resultCount {
  float:right;
  font-weight: bold;
}

.researchCustomerName {
  font-size: 1.2rem;
}

tr.row-viewed td {
  background-color: #fff5cc !important;
}
