.transactionFilters {
  display:flex;
}

.transactionFiltersAltView {
  display:flex;
  flex-direction: column;
  justify-content: flex-end;
  flex-grow: 2;
  padding-bottom:0.15rem;
  padding-left: 0.5rem;
}

.transactionFiltersAltView .filterContainer {
  margin-right: 0rem;
  margin-top: 0.5rem;
  margin-bottom: 0rem;
}

.transactionFilters .filterContainer {
  margin-right: 1rem;
  margin-top: 0.5rem;
}

.displaySide {
  display:flex;
  flex-wrap: wrap;
}
.displaySideCustomer {
  margin-right:0.25rem;
  flex-shrink: 1;
}
.displaySideTransaction {
  margin-right:0.25rem;
  flex-grow: 4;
}

.displayStacked {
  display:flex;
  flex-direction: column;
}
.displayStackedCustomer {
  margin-bottom:0.25rem;
}
.displayStackedTransaction {
  margin-bottom:0.25rem;
}
