.customerConfigHeading {
  font-size: 1.4rem;
  font-weight: normal;
}

.addressContainer {
  margin-bottom: 1rem;
  padding-bottom: 0.75rem;
  border-bottom: 1px solid #ddd;
}
