
div.report-header {
  display:flex;
  justify-content: space-between;
  margin-left: 1rem;
  margin-right: 1rem;
  margin-top: 0;
  margin-bottom:1rem;
}

div.report-message {
  color: #c00;
  font-weight: bold;
  margin-bottom:0.5rem;
}


.report-header h1, .report-header h2 {
  margin: 0;
  margin-bottom: 0.2rem;
}

.report-header h1 {
  font-size: 1.5rem;
}

.report-header h2 {
  font-size: 1.2rem;
}

h2.grand-total {
  font-size: 1.2rem;
}

.report-header img.logo {
  max-height:100%;
  max-width: 55mm;
  margin-top: 2mm;
  display:inline-block;
}

table.report-table {
  width: 100%;
  border-collapse: collapse;
}

table.report-table th, table.report-table td {
  border: 1px solid #bcbcbc;
  padding: 0.2rem;
}

table.report-table th {
  background: #ccc;
  line-height: 100%;
}

table.report-table th.nowrap, table.report-table td.nowrap {
  white-space: nowrap;
}

table.report-table th.primary-header {
  color: #fff;
  background: #421540;
  border: 1px solid #421540;
  text-align:center;
}


table.brand1 th.primary-header {
  color: #fff;
  background: #421540;
  border: 1px solid #421540;
}

table.brand2 th.primary-header {
  color: #fff;
  background: #222;
  border: 1px solid #222;
}

table.brand5 th.primary-header {
  color: #fff;
  background: #0a58ca;
  border: 1px solid #0a58ca;
}

table.brand6 th.primary-header {
  color: #fff;
  background: #192b5a;
  border: 1px solid #192b5a;
}

table.report-table tr.total-row td {
  text-align: right;
  font-weight: bold;
  background: #ccc;
}

table.report-table tr.spacer-row td {
  background:#fff;
  border: 0;
  white-space: pre-wrap;
}

table.report-table tr.summary-row td {
  background: #fff;
  border: 0;
}

table.summary-table, table.summary-table-lg {
  display:inline-table;
}

table.summary-table td, table.summary-table-lg td {
  white-space: nowrap;
  font-weight: bold;
  padding-left: 1.5rem;
  text-align: right;
}

table.summary-table-lg td {
  font-size: 1.3rem;
}

table.summary-table th, table.summary-table-lg th {
  border: 0;
  background: none;
}