
.latest-messages {
	overflow: auto;
	max-height: 300px;
}

.message-widget-icon, .message-icon {
	background-color: rgb(201, 201, 201);
	width:10px;
	height: 10px;
	border-radius: 5px;
  margin-top: 2px;
}

.message-icon {
  width:15px;
	height: 15px;
	border-radius: 8px; 
}

.message-widget-icon.unread, .message-icon.unread {
	background-color: rgb(0, 191, 158);
}
