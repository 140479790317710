.paymentConfirmationModal {
  max-width: 960px;
}

.modalBadges {
  display:flex;
  float:right;
  margin-left: 20px;
}

.modalBadges > .badge {
  background-color: #ddd;
  border-radius: 4px;
  font-size:0.8rem;
  display:inline-block;
  margin: 0 3px;
  padding: 6px 10px;
}

table.detailTable td, table.fieldTable td {
  vertical-align: top;
  padding-right: 10px;
  padding-bottom: 10px;
  line-height: 1.2rem;
}

table.fieldTable {
  margin-top: 1rem;
}

table.fieldTable td {
  padding-right: 4px;
  padding-bottom: 4px;
}

tr.transactionRow td {
  vertical-align: top;
}

td.transactionColumn {
  line-height: 1.2rem;
}