div.manage-tile-menu {
  max-width: 304px; 
}

.react-grid-item.react-grid-placeholder {
  background: rgb(0, 0, 50, 0.5);
  filter: blur(5px);
}

a.manage-tile-reset {
  color:#555 !important;
  text-decoration: none;
  position:absolute;
  bottom: 1rem;
  font-size: 0.875rem;
}