.searchContainer {
  display:flex;
  justify-content: space-between;
  margin-bottom:0.8rem;
}

.searchWrap {
  flex-grow: 1;
  max-width: 400px;
}

.reportNameCell {
  max-width: 150px;
}

.reportName {
  display: inline-block;
  margin-bottom: 0.5rem;
}

.switchActive {
  background-color: #1d9826 !important;
  border-color: #1d9826 !important;
}
.switchActive:focus {
  border-color: #1d9826 !important;
  outline: 0;
  box-shadow: 0 0 0 .25rem rgba(13, 253, 105, 0.25) !important;
}

.activeIndicator, .inactiveIndicator {
  width:12px;
  height:12px;
  background-color: #1d9826;
  border-radius: 6px;
  margin-top:.25rem !important;
  margin-left:0.75rem;
}

.inactiveIndicator {
  width: 12px;
  height:12px;
  background-color: #cacaca;
  border-radius: 6px;
  border: 1px solid #aaa;
}

.sharedReportText, .viewDetailsText {
  font-size: 0.85rem;
  color:#333;
}

.sharedReportText {
  font-weight: bold;
  margin-bottom: 0.25rem;
}

.customerName, .reportTypeName {
  font-weight: bold;
  margin-bottom: 0.25rem;
}

.transactionName {
  font-size: 0.875rem;
  margin-top: 0.25rem;
}

.scheduleText {
  font-size: 0.875rem;
  margin-top: 0.25rem;
}


.hideTransactionName {
  display:none;
}

.showMoreToggleLink {
  display:inline-block;
  margin-top: 0.33rem;
  font-weight: normal;
  font-size: 0.875rem;
  text-decoration: none;
}

.filterContainer {
  padding-top: 1rem !important;
}