.transaction-item {
	border-bottom: 1px solid #ddd;
	font-size: 0.9rem;
}

.check-field-status {
	position:relative;
}
.check-field-status .spinner-border, .check-field-status .status-icon {
	position:absolute;
	right: 12px;
	top: -28px;
}

.brand-stats {
	display:inline-block;
	margin-left: 15px;
	font-weight: normal;
	font-style: italic;
}


.transaction-select-all {
  margin-top: 0.25rem;
  margin-bottom: 0.5rem;
	padding-top:0.5rem;
	border-top: 1px dotted #aaa;
}

.transaction-container {
  overflow-x: auto;
  max-height: 200px;
  padding: 0.6rem 0.4rem;
  background-color: #fff;
  border: 1px solid rgba(0,0,0,.125)
}
