.paypalOnboardingHeader {
    font-size: 1.4rem;
    font-weight: normal;
  }
  
  .descriptionMessage, .onboardingView, .processView {
    margin-bottom:0.75rem;
    font-size:1.1rem;
  }
  
  .userContainer {
    display: flex;
    flex-wrap: wrap;
  }
  
  .userContainer > div {
    padding: 0.2rem 1rem;
    padding-right: 1.5rem
  }
  
  .userContainer > div:nth-child(3) {
    border-left: 1px solid #ccc;
  }

  .paypalOnboardingSectionHeader, .paypalOnboardingSectionHeader th {
    font-size: 1.0rem !important;
    font-weight: normal;
    color:#fff;
    background-color: #981d97 !important;
    padding:0.4rem 0.4rem !important;
  }

  .paypalOnboardingIncompleteStatusHeader, .paypalOnboardingIncompleteStatusHeader th {
    font-size: 1.0rem !important;
    font-weight: normal;
    color:#fff;
    background-color: #b42010 !important;
    padding:0.4rem 0.4rem !important;
  }

  .paypalOnboardingCompleteStatusHeader, .paypalOnboardingCompleteStatusHeader th {
    font-size: 1.0rem !important;
    font-weight: normal;
    color:#fff;
    background-color: #21981d !important;
    padding:0.4rem 0.4rem !important;
  }

  .paypalOnboardingBusiness > tbody > tr > td:nth-child(odd) {
    width: 11.0rem;
    padding: 0.3rem;
  }

  .paypalOnboardingBusiness > tbody > tr > td:nth-child(even) {
    align-self: left;
    padding: 0.3rem;
  }

  .nestedStripedTable > thead {
    background-color: #581157;
    box-shadow: none !important;
  }

  .nestedStripedTableHeader {
    font-size: 1.0rem !important;
    font-weight: normal;
    color:#fff;
  }

  .nestedStripedTable > tbody > tr > td:nth-child(odd) {
    width: 11.0rem;
    padding: 0.3rem;
    padding-left: 2.0rem;
  }

  .nestedStripedTable > tbody > tr > td:nth-child(even) {
    align-self: left;
    padding: 0.3rem;
  }

  .nestedStripedTable > tbody > tr:nth-child(odd) > td,
  .nestedStripedTable > tbody > tr:nth-child(odd) > th {
    background-color: #eeeff5;
    box-shadow: none !important;
  }

  .nestedStripedTable > tbody > tr:hover > td,
  .nestedStripedTable > tbody > tr:hover > th {
    background-color: #c0c2cc;
    box-shadow: none !important;
  }

  .lineBreaks {
    white-space: pre-wrap;
  }
