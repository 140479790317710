.reportCardBody {
  padding:0 !important;
}

.reportTools {
  background: #ccc;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}

.reportSearch, .actionButtons {
  display: flex;
  align-items: center;
  padding: 0.5rem;
}


div.reportSearchInputContainer {
  position: relative;
  display:flex;
  align-items: center;
}

input.reportSearchInput {
  height:31px !important;
  margin-left: 0.25rem;
  padding-right: 60px;
}


.resultCount, .searchNoResults {
  position: absolute;
  right: 5px;
  top: auto;
  display:inline-block;
  font-size: 0.8rem;
  border-radius: 4px;
  padding: 0 8px;
  box-sizing: border-box;
  background-color: #ccc;
  font-weight: bold;
}

.searchNoResults {
  background-color: #c00;
  color:#fff;
}

.btn {
  margin:0 0.25rem;
}

.actionButton {
  margin:0 0.25rem;
}

.reportContainer {
  max-height: 544px;
  overflow: auto;
  padding: 2rem;
  border:1px solid rgba(0,0,0,.125);
  font-size: 0.875rem;
}

.noReportLoaded {
  display: block;
  color:#aaa;
  font-size: 1.2rem;
  text-align: center;
  margin:2rem 0;
}