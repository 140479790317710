
body.theme-UniPayDirect a {
  color: #3366cc;
}

/* PRIMARY BUTTON */
body.theme-UniPayDirect .btn {
  background: #3f434e;
  color: white;
}

body.theme-UniPayDirect .btn:hover,
body.theme-UniPayDirect .btn:focus,
body.theme-UniPayDirect .btn:active {
  background: #6d6f79;
}

body.theme-UniPayDirect .btn:disabled {
  background: #6d6f79aa;
}

/* SECONDARY BUTTON */
body.theme-UniPayDirect .btn-secondary {
  background: #42495e;
}

body.theme-UniPayDirect .btn-secondary:hover,
body.theme-UniPayDirect .btn-secondary:focus,
body.theme-UniPayDirect .btn-secondary:active {
  background: #525b75;
}

body.theme-UniPayDirect .btn-secondary:disabled {
  background: #525b75aa;
}


/* SUCCESS BUTTON */
body.theme-UniPayDirect .btn-success {
  background: #358d3a;
}

body.theme-UniPayDirect .btn-success:hover,
body.theme-UniPayDirect .btn-success:focus,
body.theme-UniPayDirect .btn-success:active {
  background: #115816;
}
body.theme-UniPayDirect .btn-success:disabled {
  background: #115816aa;
}


/* DANGER BUTTON */
body.theme-UniPayDirect .btn-danger {
  background: #c94954;
}

body.theme-UniPayDirect .btn-danger:hover,
body.theme-UniPayDirect .btn-danger:focus,
body.theme-UniPayDirect .btn-danger:active {
  background: #9c3941;
}
body.theme-UniPayDirect .btn-danger:disabled {
  background: #9c3941aa;
}

/* TABLE */
body.theme-UniPayDirect .table-striped>tbody>tr:nth-child(odd)>td,
body.theme-UniPayDirect .table-striped>tbody>tr:nth-child(odd)>th {
	background-color: #6d6f7922;
  box-shadow: none !important;
}
body.theme-UniPayDirect .table-striped>tbody>tr:hover>td,
body.theme-UniPayDirect .table-striped>tbody>tr:hover>th {
	background-color: #6d6f7944;
  box-shadow: none !important;
}

/* PAGING */
body.theme-UniPayDirect .page-item .page-link {
  color: #3f434e;
}

body.theme-UniPayDirect .page-item.active .page-link {
  color:#fff;
  background: #3f434e;
  border-color: #3f434e;
}


body.theme-UniPayDirect #sidebar-wrapper {
  background: white;
  background: -moz-linear-gradient(top, white 0%, white 30%, #3f434e 100%);
  background: -webkit-linear-gradient(top, white 0%, white 30%, #3f434e 100%);
  background: linear-gradient(to bottom, #ffffff 0%, #ffffff 30%, #3f434e 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffffff', endColorstr='#3f434e',GradientType=0 );
}
body.theme-UniPayDirect #sidebar-wrapper .list-group .list-group-item {
  color: black;
}

body.theme-UniPayDirect #sidebar-wrapper a {
  color: white;
  text-decoration: none;
}
body.theme-UniPayDirect .navbar {
  background: #dedede;
}
body.theme-UniPayDirect .navbar #sidebarToggle {
  border-color: #3366cc;
  color: #3366cc;
}
body.theme-UniPayDirect .navbar .myAccount .circle {
  background: #fdbd1c !important;
  color: white;
}
body.theme-UniPayDirect #page-content-wrapper {
  background: #f1f1f1;
}
body.theme-UniPayDirect .accordion-button, body.theme-UniPayDirect .accordion-button:not(.collapsed) {
  color: #3366cc;
}
body.theme-UniPayDirect .card-header h2 {
  color: #3366cc;
}

body.theme-UniPayDirect .themed-header-bar {
  background: #dedede;
}

body.theme-UniPayDirect .themed-bg-color {
  background: #f1f1f1;
}

body.theme-UniPayDirect .themed-bg-dark {
  background: #3f434e;
  color: white;
}