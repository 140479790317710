
a.closeButton {
  text-decoration: none;
  background: rgb(220, 53, 69);
  color: #fff !important;
  border-radius: 5px;
  line-height: 100%;
}

.fileDropArea, .fileDragArea {
  display: flex;
  justify-content: center;
  align-items: center;
  border:3px dashed rgb(184, 184, 184);
  padding: 0.6rem;
}

.fileDragArea {
  border:3px dashed rgb(171, 194, 202);
  background: rgb(224, 246, 255);
}

.uploadCard {
  color:#444 !important;
  background: #f2f2f9;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 8px;
  text-decoration: none;
  padding: 0.5rem 1rem;
  margin-bottom: 0.25rem;
}

.uploadDragText {
  display: inline-block;
  margin-left: 0.5rem;
}

.processingFile {
  font-size: 0.85rem;
}