.reviewHeading {
  font-size: 1.4rem;
  margin-bottom: 1rem;
}

.reviewNotCompleteHeading {
  font-size: 1.4rem;
}

.reviewNotCompleteIcon {
  color: #990606 !important;
  margin-left: 0.4rem;
  margin-right: 0.4rem;
}

.transactionCount {
  font-weight: normal;
  font-size: 0.875rem;
  margin-top:-0.3rem;
}

.transactionReviewList {
  max-height: 130px;
  overflow-x:auto;
  line-height: 1.1rem;
  display:flex;
  flex-flow: row wrap;
}

.transactionReviewListItem {
  display:inline-block;
  background-color: #efefef;
  padding: 0.25rem 0.4rem;
  margin-right: 0.3rem;
  margin-bottom: 0.3rem;
  font-size: 0.875rem;
  flex-grow: 1;
}

.emailList {
  font-size: 0.875rem;
}