.badge.message-option {
	text-transform: uppercase;
	margin-right:3px;
}
.badge.view-count {
	margin-top:10px;
	margin-right:3px;
}

.search-results-container {
	overflow-x: hidden;
}

a.search-result-title-link {
	line-height: 1.2rem;
	font-weight: bold;
	display:inline-block;
}

.search-result-audience {
	font-size:0.8rem;
	line-height: 1rem;
	margin-bottom: 0.3rem;
}

.search-result-preview {
	font-size:0.8rem;
	line-height: 1rem;
}

.message-filters {
	overflow-x: hidden;
}

.tox-tinymce-aux {
	z-index: 900 !important;
}

.tox .tox-dialog-wrap__backdrop {
	background: rgba(0, 0, 0, 0.25);
}

@media (max-width: 1400px) {
	.search-results-table {
		font-size: 0.8rem;
	}
	button.sort-header-btn, div.sort-header-btn {
		font-size: 0.8rem;
	}
	.message-filters {
		padding: 8% !important;
	}
}