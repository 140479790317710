body.theme-UniBank a {
  color: #981d97;
}

/* PRIMARY BUTTON */
body.theme-UniBank .btn {
  background: #981d97;
  color: white;
}

body.theme-UniBank .btn:hover,
body.theme-UniBank .btn:focus,
body.theme-UniBank .btn:active {
  background: #581157;
}

body.theme-UniBank .btn:disabled {
  background: #581157aa;
}

/* SECONDARY BUTTON */
body.theme-UniBank .btn-secondary {
  background: #5f2a86;
}

body.theme-UniBank .btn-secondary:hover,
body.theme-UniBank .btn-secondary:focus,
body.theme-UniBank .btn-secondary:active {
  background: #3f0a66;
}

body.theme-UniBank .btn-secondary:disabled {
  background: #3f0a66aa;
}


/* SUCCESS BUTTON */
body.theme-UniBank .btn-success {
  background: #1d9826;
}

body.theme-UniBank .btn-success:hover,
body.theme-UniBank .btn-success:focus,
body.theme-UniBank .btn-success:active {
  background: #115816;
}
body.theme-UniBank .btn-success:disabled {
  background: #115816aa;
}


/* DANGER BUTTON */
body.theme-UniBank .btn-danger {
  background: #e12858;
}

body.theme-UniBank .btn-danger:hover,
body.theme-UniBank .btn-danger:focus,
body.theme-UniBank .btn-danger:active {
  background: #a5173c;
}
body.theme-UniBank .btn-danger:disabled {
  background: #a5173caa;
}

/* TABLE */
body.theme-UniBank .table-striped>tbody>tr:nth-child(odd)>td,
body.theme-UniBank .table-striped>tbody>tr:nth-child(odd)>th {
	background-color: #eeeff5;
  box-shadow: none !important;
}
body.theme-UniBank .table-striped>tbody>tr:hover>td,
body.theme-UniBank .table-striped>tbody>tr:hover>th {
	background-color: #dadce8;
  box-shadow: none !important;
}

/* PAGING */
body.theme-UniBank .page-item .page-link {
  color: #981d97;
}

body.theme-UniBank .page-item.active .page-link {
  color:#fff;
  background: #981d97;
  border-color: #981d97;
}


body.theme-UniBank #sidebar-wrapper {
  background: #d70a84;
  background: -moz-linear-gradient(top, #d70a84 0%, #991d97 30%, #3f0a66 100%);
  background: -webkit-linear-gradient(top, #d70a84 0%, #991d97 30%, #3f0a66 100%);
  background: linear-gradient(to bottom, #d70a84 0%, #991d97 30%, #3f0a66 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#d70a84', endColorstr='#3f0a66',GradientType=0 );
}

body.theme-UniBank #sidebar-wrapper .brand {
  background: #dadce8;
  margin: -2rem;
  padding: 2rem;
  max-width: 304px;
}

body.theme-UniBank #sidebar-wrapper .brand img {
  max-width: 130px;
}

body.theme-UniBank #sidebar-wrapper .list-group .list-group-item {
  color: white;
}

body.theme-UniBank #sidebar-wrapper a {
  color: white;
  text-decoration: none;
}

body.theme-UniBank .navbar {
  background: #dadce8;
}

body.theme-UniBank .navbar #sidebarToggle {
  border-color: #981d97;
  color: #981d97;
}

body.theme-UniBank .navbar .myAccount .circle {
  background: #981d97 !important;
  color: white;
}

body.theme-UniBank #page-content-wrapper {
  background: #f4f5fc;
}

body.theme-UniBank .accordion-button, body.theme-UniBank .accordion-button:not(.collapsed) {
  color: #981d97;
}

body.theme-UniBank .card-header h2 {
  color: #981d97;
}

body.theme-UniBank .themed-header-bar {
  background: #dadce8;
}

body.theme-UniBank .themed-bg-color {
  background: #f4f5fc;
}

body.theme-UniBank .themed-bg-dark {
  background: #981d97;
  color: white;
}