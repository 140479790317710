

@media (min-width: 768px) {
  .animate {
    animation-duration: 0.4s;
    -webkit-animation-duration: 0.4s;
    animation-timing-function: ease-out;
    -webkit-animation-timing-function: ease-out;
    animation-fill-mode: both;
    -webkit-animation-fill-mode: both;
  }
}

@keyframes slideIn {
  0% {
    transform-origin: top right;
    transform: scale(0);
    opacity: 0;
  }

  60% {
    transform-origin: top right;
    transform: scale(1.1);
    opacity: 1;
  }

  100% {
    transform-origin: top right;
    transform: scale(1);
    opacity: 1;
  }
}

@-webkit-keyframes slideIn {
  0% {
    -webkit-transform-origin: top right;
    -webkit-transform: transform;
    -webkit-opacity: 0;
  }

  60% {
    -webkit-transform-origin: top right;
    -webkit-transform: scale(1.1);
    -webkit-opacity: 1;
  }

  100% {
    -webkit-transform-origin: top right;
    -webkit-transform: translateY(0);
    -webkit-opacity: 1;
  }
}

.slideIn {
  -webkit-animation-name: slideIn;
  animation-name: slideIn;
}
