.transactionManager {
  border:1px solid rgba(0,0,0,.125);
  background-color: #fff;
  padding: 1rem;
}

.transactionDetails {
  padding-top:1rem;
  margin-top:1rem;
  border-top:1px solid rgba(0,0,0,.125);
  display:flex;
}

.sectionMenu {
  padding-right: 1.5rem;
  flex-shrink: 1;
  display: block; /* TODO - remove when ready to show transaction manager sections */
}
.sectionDisplay {
  flex-grow: 1;
  min-width: 0;
}

.transactionConfigSection {
  overflow-x: auto;
}

.sectionMenu a.sectionMenuItem, .sectionMenu a.sectionMenuItemActive {
  font-size: 0.875rem;
  border-radius: 4px;
  margin: 0.25rem 0;
  padding: 0.1rem 1rem;
  display:flex;
  align-items: center;
  text-decoration: none;
  color:#333 !important;
  white-space: nowrap;
}

.sectionMenu a.sectionMenuItem:hover {
  background: #f0f2f3;
}

.sectionMenu a.sectionMenuItemActive {
  font-weight: bold;
  cursor: default;
  background: #f0f2f3;
}

.sectionName {
  margin-left:0.5rem;
}
