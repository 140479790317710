div.role-group-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

div.role-group {
  margin-bottom: 2rem;
  flex-basis: 250px;
	flex-grow: 1;
	flex-shrink: 0;
}

div.role-group-full-width {
  margin-bottom: 2rem;
  flex-basis: 100%;
	flex-grow: 0;
	flex-shrink: 0;
}

div.role-group legend, div.role-group-full-width legend {
  font-family: 'Poppins', sans-serif;
  font-size: 0.875rem !important;
  font-weight: 600;
  width: auto;
}

div.role-group-title {
  background: #efefef;
  border-radius: 4px;
  padding-top: 0.75rem;
  padding-left: 2rem;
}

div.role-group div.role-permissions {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  padding-top:0.5rem;
  padding-left: 0.5rem;
}

div.role-group-full-width div.role-permissions {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding-top:0.5rem;
  padding-left: 0.5rem;
}

div.role-group-full-width div.permission {
	flex-basis: 250px;
	flex-shrink: 1;
}