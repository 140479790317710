.circleWrapper {
  position: relative;
}

.circleContent {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  line-height: 1em;
  text-align: center;
}