.scheduleReportModal {
  max-width: 960px !important;
}

.wizardStep {
  font-size: 0.875rem;
  border-radius: 4px;
  margin: 0.25rem 0;
  padding: 0.1rem 0.4rem;
  display:flex;
  align-items: center;
  text-decoration: none;
  color:#333 !important;
}

.wizardStep:hover {
  background: #f0f2f3;
}

.wizardStepActive {
  font-size: 0.875rem;
  border-radius: 4px;
  margin: 0.25rem 0;
  padding: 0.1rem 0.4rem;
  display:flex;
  align-items: center;
  text-decoration: none;
  color:#333 !important;
  font-weight: bold;
  cursor: default;
  background: #f0f2f3;
}


@media (max-width: 1199px) {
  .wizardStepContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top:0 !important;
    margin:0 !important;
    padding-bottom:0.5rem;
    margin-bottom:0.5rem;
    border-bottom:1px solid #ddd;
  }
  .wizardStep {
    flex-grow: 1;
    background: #f0f2f3;
    padding: 0.5rem 0.5rem;
    line-height: normal;
    margin-left: 0.25rem;
    margin-right: 0.25rem;
    justify-content: center;
    border: 2px solid #dbdee0;
  }
  .wizardStepActive {
    flex-grow: 1;
    background: #f0f2f3;
    padding: 0.5rem 0.5rem;
    line-height: normal;
    margin-left: 0.25rem;
    margin-right: 0.25rem;
    justify-content: center;
    border: 2px solid #333;
  }
}

.stepNumber {
  display:none;
}

@media (max-width: 550px) {
  .stepNumber {
    display:inline-block;
    font-size:1.1rem;
  }
  .stepName {
    display:none;
  }
}

.wizardStepIcon {
  color: #cacdd3 !important;
  margin-right:0.25rem;
}

.wizardStepActiveIcon {
  color: #333 !important;
  margin-right:0.25rem;  
}

.wizardStepCompleteIcon {
  color: #1a6617 !important;
  margin-right:0.25rem;
}

.wizardStepWarningIcon {
  color: #990606 !important;
  margin-right:0.25rem;
}

.transactionOptions {
  display:flex;
  justify-content: space-between;
  align-items: center;
}

.showAllTransactions {
  padding: 0.5rem 0.75rem;
  padding-bottom: 0;
  background: #f0f2f3;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
}

.showTransactionOptions {
  display:flex;
  justify-content: space-evenly;
  padding: 0.5rem 0.75rem;
  padding-bottom: 0;
  background: #f0f2f3;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
}

.scheduleTypeOptions {
  display: flex;
  flex-wrap: wrap;
  margin-top: 0.5rem;
}

.scheduleTypeOption {
  padding-left: 0 !important;
  flex-grow: 1;
  margin-right: 1rem;
  margin-bottom: 0.5rem;
}

.scheduleTypeLabel {
  padding:1rem;
  padding-left:2rem;
  border: 1px solid #f0f2f5;
  background: #f0f2f5;
  border-radius: 5px;
  width: 100%;
}

.scheduleTypeLabelActive {
  padding:1rem;
  padding-left:2rem;
  background: #f0f2f5;
  border: 1px solid #0d6efd;
  border-radius: 5px;
  width: 100%;
}

.dateSelect {
  max-width: 200px;
}
