.progressBar {
  height: 3px;
  background-color: rgba(5, 114, 206, 0.2);
  width: 100%;
  overflow: hidden;
  border-radius:2px;
}

.progressBarIndeterminate {
  width: 100%;
  height: 100%;
  background-color: rgb(5, 114, 206);
  animation: indeterminateAnimation 1.4s infinite linear;
  transform-origin: 0% 50%;
  border-radius:2px;
}

.progressBarValue {
  height: 100%;
  background-color: rgb(5, 114, 206);
  transition: width 0.25s;
  border-radius:2px;
}

.percentageText {
  font-size: 0.85rem;
}

@keyframes indeterminateAnimation {
  0% {
    transform:  translateX(0) scaleX(0);
  }
  40% {
    transform:  translateX(0) scaleX(0.3);
  }
  100% {
    transform:  translateX(100%) scaleX(0.8);
  }
}