
.resultCount {
  float:right;
  font-weight: bold;
}

.researchCustomerName {
  font-size: 1.2rem;
}

tr.rowViewed td {
  background-color: #fff5cc !important;
}

.detailHeader {
  padding-bottom: 1rem;
  margin-bottom: 1rem;
  border-bottom: 1px solid #ddd;
}

.detailHeader > h3 {
  font-size: 1.5rem;
  font-weight: normal;
  margin: 0;
}

.fileKeyName {
  font-size:0.75rem;
  color: #666;
}

.fuzzyLabel {
  font-size:0.75rem !important;
}

.fuzzyAmounts {
  margin-top:-0.75rem;
}

.paidBadge, .unpaidBadge {
  color: #555;
  display: block;
  padding: 0.1rem 0.5rem;
  width: 100%;
  text-align: center;
  border-radius: 8px;
  font-size: 0.875rem;
  text-wrap: nowrap;
}

.paidBadge {
  color: #fff;
  background-color: #009632;
}

.paidContainer .unpaidBadge  {
  color: #333;
  background-color: #cccccc;
}