.dayNumber {
  display:inline-block;
  max-width: 80px;
  height:32px !important;
  padding: 0.25rem 0.4rem !important;
}

.dropdown {
  height:32px;
  padding: 0.25rem 2.25rem 0.25rem 0.75rem !important;
  font-size: 0.875rem !important;
  width:auto !important;
}

.sectionDisabled {
  opacity: 0.5;
}