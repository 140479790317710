.container {
  display: flex;
  background: rgb(240, 242, 245);
  padding: 0.5rem;
  border-left:6px solid rgb(203, 204, 207);
}


.icon {
  color:#444;
  margin-right: 0.5rem;
}


.content {
  font-size: 0.9rem;
  font-style: italic;
  line-height: 1.3rem;
  padding-top: 0.2rem;
  padding-bottom: 0.2rem;
}