
.fileBrowserContainer {
  background: #fff;
  border: 1px solid #ccceda;
  display:flex;
  flex-direction: column;
  flex-grow: 1;
}

.pathSegmentContainer {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  padding: 0.25rem 0.5rem;
  background: #ddd;
  background: linear-gradient(0deg, #e4e5ec 0%, #bcbeca 100%);
}

.pathSegment {
  background: #fff;
  border: 1px solid #cccbcb;
  border-bottom: 1px solid #aaa;
  color: #333;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-family: 'Poppins', sans-serif;
  font-size: 0.875rem;
  font-weight: 700;
  padding: .65rem 1rem;
  margin-right: 0;
  line-height: 100%;
}

.pathSegment:disabled {
  color:#666;
  background: #f4f5fc;
}

.pathSegment:first-child {
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
}
.pathSegment:last-child {
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
}
.pathSegment:hover {
  background: #f4f5fc;
}

.actionButtonRow {
  background: #e2e3ec;
  display:flex;
  padding: 0.25rem 0.25rem;
}

.actionButton {
  padding: .65rem 1rem;
  margin: 0.25rem;
}

.directoryContentsContainer {
  display: flex;
  flex-grow: 1
}

.directoryContents {
  margin: 0.5rem;
  flex-grow: 1;
  position: relative;
}

.fileList {
  overflow-y: auto;
  min-height: 200px;
  max-height: 400px;
}

.modalFileList {
  overflow-y: auto;
  min-height: 200px;
  max-height: 90%;
  position: absolute;
  width: 100%;
}

.modalFooter {
  background: #e2e3ec;
}

.itemHeader {
  display: flex;
  color:#666 !important;
  padding: 0.25rem 0.5rem;
  font-weight: bold;
}

.itemHeader>div:last-child {
  min-width: 60px;
}

.itemRow {
  display: flex;
  border-bottom: 1px solid #f4f5fc;
  align-items: center;
  text-decoration: none;
  color:#666 !important;
  padding: 0.25rem 0.5rem;
}

.itemRow>div:last-child {
  min-width: 60px;
}


.itemRowActive {
  background-color: rgb(224, 246, 255);
  border-left: 6px solid rgb(172, 218, 236);
}

.itemRow:hover {
  background-color: rgb(224, 246, 255);
}

.itemRow:last-child {
  border-bottom: none;
}

.itemName {
  flex-grow: 1;
}

.itemRowLink {
  flex-grow: 1;

}
.itemRowLink:hover {
  color:#111 !important;
}

.fileDetails {
  padding: 1rem;
  background: #f4f5fc;
  min-width: 320px;
  display:flex;
  flex-direction: column;
}

.fileDetailsName {
  width: 320px;
  font-weight: bold;
  text-overflow: ellipsis;
  line-height: 1.2rem;
  margin: 0.3rem 0;
}

.previewImage {
  width: 100%;
  max-width: 300px;
  max-height: 200px;
}

.genericFile {
  border: 3px solid #666;
  border-radius: 8px;
  background-color: #fff;
  text-align: center;
  padding: 5%;
  width: 100px;
  height: 100px;
  font-size: 36px;
  color:#666;
  overflow:hidden;
}


@media (max-width: 1200px) {
  .directoryContentsContainer {
    display: block;
  }

  .directoryContentsContainer {
    display: block;
  }
  
  .fileDetails {
    min-width: 100%;
    display:block;
  }
  .fileDetailsName {
    width: 100%;
  }
  .modalFileList {
    max-height: 300px;
    position: relative;
  }
}