
button.sort-header-btn, div.sort-header-btn {
  font-family: sans-serif;
	font-size: 0.9rem;
	text-align: left;
	color: #333;
	background: none;
	margin: 0;
	padding: 0;
	border: none;
	cursor: pointer;
  font-weight:bold;
  width:auto;
	line-height: 1rem;
}
div.sort-header-btn {
  cursor: auto;
	margin-bottom:4px;
}

.sortingArrows svg.up, .sortingArrows svg.down {
  color:#ccc;
}

.sortingArrows svg.down {
  margin-top:-4px;
}

.sortingArrows svg.active {
  color:#000;
}