
.wrapper {
  display: flex;
}

.everyDayOptionContainer {
  flex-shrink: 1;
  padding-right: 1rem;
  margin-right: 1rem;
  border-right:1px solid #ddd;
}

.dayWrapper {
  display: flex;
  flex-wrap: wrap;
  flex: 2;
}

.daySelect {
  padding-right: 1rem;
  padding-bottom: 0.5rem;
}
.daySelectShort {
  padding-right: 0.75rem;
  padding-bottom: 0.5rem;
}

.dayLabel {
  font-weight: normal;
}

.dayInput {
  margin-right:0.25rem;
}